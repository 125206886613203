import React, { Component } from 'react'
import { date2str } from './utils';

const GEList = (props) => {
  const geListHtml = props.geList.map((ge, i) => (
    <tr key={"geList-" + String(i)}>
      <th>{i}</th>
      <td><input type="text" value={ge.deviceId} onChange={(e) => { ge.deviceId = e.target.value; props.setState({ geList: props.geList }) }} /></td>
      <td><input type="text" value={ge.mac} onChange={(e) => { ge.mac = e.target.value; props.setState({ geList: props.geList }) }} /></td>
      <td><input type="text" value={ge.location} onChange={(e) => { ge.location = e.target.value; props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="0.1" value={ge.cx} onChange={(e) => { ge.cx = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="0.1" value={ge.cy} onChange={(e) => { ge.cy = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="1" value={ge.degx} onChange={(e) => { ge.degx = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="1" value={ge.degy} onChange={(e) => { ge.degy = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="0.1" value={ge.height} onChange={(e) => { ge.height = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="0.0001" value={ge.lon} onChange={(e) => { ge.lon = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <td><input type="number" step="0.0001" value={ge.lat} onChange={(e) => { ge.lat = Number(e.target.value); /* props.updateDists(); */ props.setState({ geList: props.geList }) }} /></td>
      <button className="btn btn-danger font-weight-bold" onClick={() => props.removeGE(i)}>-</button>
    </tr>
  ));
  return(
    <table className="geList">
      <thead>
        <tr>
          <th>ID</th>
          <th>DeviceID</th>
          <th>MAC</th>
          <th>Location</th>
          <th>Ct-X</th>
          <th>Ct-Y</th>
          <th>Deg-X</th>
          <th>Deg-Y</th>
          <th>Height</th>
          <th>LON</th>
          <th>LAT</th>
        </tr>
      </thead>
      <tbody>
        {geListHtml}
      </tbody>
    </table>
  )
};

const GEMatrix = (props) => {
  const pcMatrixHtml = props.pcMatrix.map((row, i) => {
    const rowHtml = row.map((pc, j) => (
      <td key={"pcMatrix-"+String(i)+"-"+String(j)} className={"pcMatrix-" + String(i%2) + "-" + String(j%2)}>
        <div className="container" style={{ width: '135px' }}>
          <div className="row">
            <div className="col-6">
              <span className={pc.dist < 1 ? "text-danger font-weight-bold" : pc.dist < 10 ? "text-warning" : pc.dist < 25 ? "text-success" : "text-primary"}>{pc.dist.toFixed(1)}</span>
            </div>
            <div className="col-6">
              <input type="number" value={pc.fr} onChange={(e) => { pc.fr = Number(e.target.value); props.setState({ pcMatrix: props.pcMatrix }) }} />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <input type="number" value={pc.df} onChange={(e) => { pc.df = Number(e.target.value); props.setState({ pcMatrix: props.pcMatrix }) }} />
            </div>
            <div className="col-4">
              <input type="number" value={pc.dm} onChange={(e) => { pc.dm = Number(e.target.value); props.setState({ pcMatrix: props.pcMatrix }) }} />
            </div>
            <div className="col-4">
              <input type="number" value={pc.ds} onChange={(e) => { pc.ds = Number(e.target.value); props.setState({ pcMatrix: props.pcMatrix }) }} />
            </div>
          </div>
        </div>
      </td>
    ));
    return (
      <tr key={"pcMatrix-" + String(i)}>
        <th>{i}</th>
        {rowHtml}
      </tr>
    );
  });

  return(
    <table className="pcMatrix" style={{ marginBottom: '1rem' }}>
      <thead>
        <tr>
          <th>＼</th>
          {props.pcMatrix.map((_, i) => <th>{i}</th>)}
        </tr>
      </thead>
      <tbody>
        {pcMatrixHtml}
      </tbody>
    </table>
  )
};

class SensorConfig extends Component {

  constructor(props) {
    super(props);
    const { geList, pcMatrix } = this.props;
    this.state = {
      geList,
      pcMatrix,
    };
  }

  addGE() {
    this.state.geList.push({
      deviceId: "GE-" + String(this.state.geList.length),
      mac: "00:11:22:33:44:55",
      location: "",
      cx: 0.0,
      cy: 0.0,
      degx: 0.0,
      degy: 0.0,
      height: 2.70,
      lon: 136.9660817,
      lat: 35.1553273,
    });
    // const nRow = [{
    //   dist: 0,
    //   fr: 1,
    //   df: 5,
    //   dm: 10,
    //   ds: 30,
    // }];
    // this.state.pcMatrix.forEach((row, i) => {
    //   row.push({
    //     dist: 0,
    //     fr: 1,
    //     df: 5,
    //     dm: 10,
    //     ds: 30,
    //   });
    //   nRow.push({
    //     dist: 0,
    //     fr: 1,
    //     df: 5,
    //     dm: 10,
    //     ds: 30,
    //   });
    // });
    // this.state.pcMatrix.push(nRow);

    this.setState({
      geList: this.state.geList,
      // pcMatrix: this.state.pcMatrix,
    });

    // this.updateDists();
  }

  removeGE(index) {
    console.log("Removing " + String(index));
    this.state.geList.splice(index, 1);
    // this.state.pcMatrix.splice(index, 1);
    // this.state.pcMatrix.forEach(row => {
    //   row.splice(index, 1);
    // });

    this.setState({
      geList: this.state.geList,
      // pcMatrix: this.state.pcMatrix,
    });
  }

  importGEs() {
    const setState = this.setState.bind(this);
    const { updatePCs } = this.props;

		const showOpenFileDialog = () => {
			return new Promise(resolve => {
				const input = document.createElement('input');
				input.type = 'file';
				input.accept = '.json, application/json';
				input.onchange = event => { resolve(event.target.files[0]); };
				input.click();
			});
		};
		
		const readAsText = file => {
			return new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsText(file);
				reader.onload = () => { resolve(reader.result); };
			});
		};
		
		(async () => {
			const file = await showOpenFileDialog();
			const content = await readAsText(file);
			// 内容表示
			console.log("import", content);
			const { geList, pcMatrix } = JSON.parse(content);
      setState({ geList, pcMatrix });
      updatePCs({ geList, pcMatrix });
		})();
	}

	exportGEs() {
		const json = JSON.stringify(this.state);
		console.log("export", json);
		const a = document.createElement('a');
		a.href = URL.createObjectURL(new Blob([json], {type: 'application/json'}));
		a.download = 'gv_ges_' + date2str(new Date(), 'YYYYMMDDhhmmss') + '.json';
		a.style.display = 'none';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
  }
  
  updateDists() {
    const { geList, pcMatrix } = this.state;
    for(let i = 0; i < geList.length; i++){
      for(let j = 0; j <= i; j++){
        pcMatrix[i][j].dist = this.calcDist(geList[i].lat, geList[i].lon, geList[j].lat, geList[j].lon);
        pcMatrix[j][i].dist = pcMatrix[i][j].dist;
      }
    }
    this.setState({ pcMatrix });
  }

  calcDist(lat1, lon1, lat2, lon2) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * lat1/180;
      const radlat2 = Math.PI * lat2/180;
      const theta = lon1-lon2;
      const radtheta = Math.PI * theta/180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) dist = 1;
      //return Math.acos(dist) * 180/Math.PI * 60 * 1.1515 * 1609.344;
      return Math.acos(dist) * 20014123.8528 / Math.PI;
    }
  }
  
  updateDurs() {
    const { geList, pcMatrix } = this.state;
    for(let i = 0; i < geList.length; i++){
      for(let j = 0; j <= i; j++){
        pcMatrix[i][j].df = Math.max(pcMatrix[i][j].dist / (1.1 * 5), 5);
        pcMatrix[j][i].df = pcMatrix[i][j].df;
        pcMatrix[i][j].dm = Math.max(pcMatrix[i][j].dist / (1.1 * 1), 15);
        pcMatrix[j][i].dm = pcMatrix[i][j].dm;
        pcMatrix[i][j].ds = Math.max(pcMatrix[i][j].dist / (1.1 * 0.5), 60);
        pcMatrix[j][i].ds = pcMatrix[i][j].ds;
      }
    }
    this.setState({ pcMatrix });
  }

  render(){
    const { geList, pcMatrix } = this.state;
    const setState = this.setState.bind(this);
    const removeGE = this.removeGE.bind(this);
    const updateDists = this.updateDists.bind(this);
    return (
      <div className="pceditor">
        <span className="mt-3">
          <button className="btn btn-primary font-weight-bold" onClick={() => this.addGE()}>+</button>
          <button className="btn btn-info font-weight-bold" onClick={() => this.updateDists()}>距離更新</button>
          <button className="btn btn-secondary font-weight-bold" onClick={() => this.importGEs()}>インポート</button>
          <button className="btn btn-secondary font-weight-bold" onClick={() => this.exportGEs()}>エクスポート</button>
          <button className="btn btn-secondary font-weight-bold" onClick={() => this.updateDurs()}>移動時間自動計算</button>
          <button className="btn btn-success font-weight-bold" onClick={() => this.props.updatePCs({ geList })}>適用</button>
        </span>
        <h2 className="mt-3">GridEye List</h2>
        <GEList geList={geList} setState={setState} removeGE={removeGE} updateDists={updateDists} />
        {/* <h2 className="mt-3">GridEye Relation</h2> */}
        {/* <GEMatrix pcMatrix={pcMatrix} setState={setState} /> */}
      </div>
    );
  };

}

export default SensorConfig;
