import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import * as d3 from 'd3';
import Home from "./Home";
// import logo from './logo.svg';
import './App.css';
import SensorConfig from './SensorConfig';
import { date2str } from './utils';

const MAX_LOG_LENGTH = 200;

class App extends Component {

  constructor(props) {
    super(props);
    this.zero = new Date(0);
    this.margin = { top: 5, right: 20, bottom: 25, left: 50, scbar: 30 };
    this.x2 = undefined;
    this.state = {
      geList: [
        {
          deviceId: "raspberrypi_48",
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: 2,
          cy: 6,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273
      },
      {
          deviceId: "raspberrypi_49",
          mac: "00:11:22:33:44:55",
          location: "UCLab-1",
          cx: -2,
          cy: 6,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273
      },
      {
          deviceId: "raspberrypi_50",
          mac: "00:11:22:33:44:55",
          location: "UCLab-2",
          cx: -2,
          cy: 10,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273
      },
      {
          deviceId: "raspberrypi_51",
          mac: "00:11:22:33:44:55",
          location: "UCLab-3",
          cx: 2,
          cy: 10,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273
      },
      {
          deviceId: "raspberrypi_16",
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: -8.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_17",
          mac: "00:11:22:33:44:55",
          location: "UCLab-1",
          cx: -4.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_18",
          mac: "00:11:22:33:44:55",
          location: "UCLab-2",
          cx: 0.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_19",
          mac: "00:11:22:33:44:55",
          location: "UCLab-3",
          cx: 4.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_20",
          mac: "00:11:22:33:44:55",
          location: "UCLab-4",
          cx: 8.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_21",
          mac: "00:11:22:33:44:55",
          location: "UCLab-5",
          cx: 8.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_22",
          mac: "00:11:22:33:44:55",
          location: "UCLab-6",
          cx: 4.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_23",
          mac: "00:11:22:33:44:55",
          location: "UCLab-7",
          cx: 0.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_24",
          mac: "00:11:22:33:44:55",
          location: "UCLab-8",
          cx: -4.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_25",
          mac: "00:11:22:33:44:55",
          location: "UCLab-9",
          cx: -8.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_105",
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: -2.0,
          cy: -6.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
        {
          deviceId: "raspberrypi_107",
          mac: "00:11:22:33:44:55",
          location: "UCLab-1",
          cx: 2.0,
          cy: -6.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
        },
      ],
      pcMatrix: [[
        {
          dist: 0,
          fr: 1,
          df: 5,
          dm: 10,
          ds: 30,
        }
      ]],
      dmap: {
        "raspberrypi_48": {
          i: 0,
          deviceId: "raspberrypi_48",
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: 2,
          cy: 6,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_49": {
          i: 0,
          deviceId: "raspberrypi_49",
          mac: "00:11:22:33:44:55",
          location: "UCLab-1",
          cx: -2,
          cy: 6,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_50": {
          i: 0,
          deviceId: "raspberrypi_50",
          mac: "00:11:22:33:44:55",
          location: "UCLab-2",
          cx: -2,
          cy: 10,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_51": {
          i: 0,
          deviceId: "raspberrypi_51",
          mac: "00:11:22:33:44:55",
          location: "UCLab-3",
          cx: 2,
          cy: 10,
          degx: 180,
          degy: 180,
          height: 2.7,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_16": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: -8.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_17": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-1",
          cx: -4.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_18": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-2",
          cx: 0.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_19": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-3",
          cx: 4.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_20": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-4",
          cx: 8.0,
          cy: -2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_21": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-5",
          cx: 8.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_22": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-6",
          cx: 4.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_23": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-7",
          cx: 0.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_24": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-8",
          cx: -4.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_25": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-9",
          cx: -8.0,
          cy: 2.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_105": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: -2.0,
          cy: -6.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
        "raspberrypi_107": {
          i: 0,
          mac: "00:11:22:33:44:55",
          location: "UCLab-0",
          cx: 2.0,
          cy: -6.0,
          degx: 0.0,
          degy: 0.0,
          height: 2.70,
          lon: 136.9660817,
          lat: 35.1553273,
          data: [],
        },
      },
      bins: 200,
      startTime: new Date(),
      lastTime: this.zero,
      focusStartTime: new Date(),
      focusLastTime: this.zero,
      currentTime: this.zero,
      gwidth: document.body.clientWidth - this.margin.left - this.margin.right - this.margin.scbar,
      gheight: 200 - this.margin.top - this.margin.bottom,
      lastW: 0,
      lastE: document.body.clientWidth - this.margin.left - this.margin.right - this.margin.scbar,
      minTemp: 35,
      maxTemp: 5,
      avgMinTemp: 35,
      avgMaxTemp: 5,
      stopReceive: false,
    }

    this.worker = new Worker('src/worker.js');
    const _self = this;
    this.worker.addEventListener('message', event => {
      let { startTime, lastTime, focusStartTime, focusLastTime, lastW, lastE, minTemp, maxTemp, avgMinTemp, avgMaxTemp, currentTime, stopReceive } = this.state;
      if (stopReceive) return;
      const shouldFollowStartTime = startTime.getTime() === focusStartTime.getTime();
      const shouldFollowLastTime = lastTime.getTime() === focusLastTime.getTime();
      const dmap = this.state.dmap;
      const keys = Object.keys(dmap);
      //			console.log("Data from WebWorker", JSON.parse(JSON.stringify(event.data)))
      if (event.data.type === "ge") { // for GridEye data.
        const dt = event.data
        console.log("GE Receive Event:", JSON.parse(JSON.stringify(dt)));
        dt.data.forEach(d => {
          const avg = d3.mean(d.temps);
          const id = `${dt.deviceId}_${d.id}`;
          if (keys.includes(id)) {
            const cur = dmap[id];
            cur.data.push({
              i: cur.i,
              typ: d.typ,
              id: id,
              seq: d.seq,
              ts: d.ts,
              time: d.time,
              ms: d.time.getTime(),
              temps: d.temps,
              avg,
              audioSpectrum: d.audioSpectrum,
            });
            if (cur.data.length > MAX_LOG_LENGTH) {
              cur.data.shift();
              startTime = cur.data[0].time;
            }
            d.temps.forEach(temp => {
              if (minTemp > temp) minTemp = temp;
              if (maxTemp < temp) maxTemp = temp;  
            })
            if (avgMinTemp > avg) avgMinTemp = avg;
            if (avgMaxTemp < avg) avgMaxTemp = avg;
          }
        })
        if (dt.data[0] != null) {
          if (startTime > dt.data[0].time) startTime = dt.data[0].time;
          if (lastTime < dt.data[dt.data.length - 1].time) lastTime = dt.data[dt.data.length - 1].time;
        }
      } else if (event.data.type === "ges") {
        console.log("message:ges", event.data);
        const mp = event.data.map
        //				console.log(mp)
        for (const pt of mp) {
          if (keys.includes(pt[0])) { // already?
            const cur = dmap[pt[0]];
            pt[1].data.forEach(v => {
              v.ms = v.time.getTime();
              v.i = cur.i;
              v.avg = d3.mean(v.temps);
              if (avgMinTemp > v.avg) avgMinTemp = v.avg;
              if (avgMaxTemp < v.avg) avgMaxTemp = v.avg;
            });
            Array.prototype.push.apply(cur.data, pt[1].data)
            if (pt[1].data[0] != null) {
              if (startTime > pt[1].data[0].time) startTime = pt[1].data[0].time;
              if (lastTime < pt[1].data[pt[1].data.length - 1].time) lastTime = pt[1].data[pt[1].data.length - 1].time;
              pt[1].data.forEach(d => {  // eslint-disable-line no-loop-func
                d.temps.forEach(temp => {
                  if (minTemp > temp) minTemp = temp;
                  if (maxTemp < temp) maxTemp = temp;  
                })
              });
            }
          }
        }
        //				console.log("push", cur)
      }

      if (focusLastTime === this.zero) {
        console.log("Set time");
        focusLastTime = lastTime;
        focusStartTime = startTime;
      }

      if (currentTime.getTime() < lastTime.getTime()) {
        currentTime = lastTime;
      }

      if (shouldFollowStartTime) {
        focusStartTime = startTime;
      } else if (_self.x2 !== undefined) {
        lastW = _self.x2(focusStartTime);
      }
      if (shouldFollowLastTime) {
        focusLastTime = lastTime;
      } else if (_self.x2 !== undefined) {
        lastE = _self.x2(focusLastTime);
      }

      _self.setState({
        dmap,
        startTime,
        lastTime,
        focusStartTime,
        focusLastTime,
        currentTime,
        lastW,
        lastE,
        minTemp,
        maxTemp,
        avgMinTemp,
        avgMaxTemp,
      });
    });

    // visibilitychangeイベントリスナー
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
          // ページが非表示の時、Workerに動作を停止するよう通知
          this.worker.postMessage('pause');
      } else {
          // ページが表示された時、Workerに動作を再開するよう通知
          this.worker.postMessage('resume');
      }
    });
    
    // blurイベントリスナー
    window.addEventListener('blur', () => {
      // ウィンドウが非アクティブになった時、Workerに動作を停止するよう通知
      this.worker.postMessage('pause');
    });

    window.addEventListener('focus', () => {
      // ウィンドウがアクティブになった時、Workerに動作を再開するよう通知
      this.worker.postMessage('resume');
    });

    window.addEventListener( "resize", () => {
      this.setState({
        gwidth: document.body.clientWidth - this.margin.left - this.margin.right - this.margin.scbar
      })
		});
  }

  componentDidMount() {
    this.modeSwitch(window.location.pathname);
    this.createDynamicBase();
  }

  componentDidUpdate() {
		this.createDynamicBase();
	}

	createDynamicBase() {
    const { geList, dmap, startTime, lastTime, gwidth, gheight, avgMinTemp, avgMaxTemp } = this.state;
    const { margin } = this;
    const setState = this.setState.bind(this);
		const _self = this;
		const vis = "#datavisBase-new"

		d3.select(vis).selectAll("*").remove()
		const svg = d3.select(vis)
			.append("svg")
			.attr("height", gheight / 2 + margin.top + margin.bottom)
			.attr("width", gwidth + margin.left + margin.right)
			.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")")
		const sdt = dmap[geList[0].deviceId]; // always use 0
		const sdt2 = dmap[geList[geList.length - 1].deviceId]; // always use 0

		this.x2 = d3.scaleTime()
			.domain([startTime, lastTime])
      .rangeRound([0, gwidth])
    let { x2 } = this;

		var y2 = d3.scaleLinear()
			.range([gheight / 2, 0])
			.domain([avgMinTemp, avgMaxTemp]);

    svg.append("path")
      .datum(sdt.data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line().x(d => x2(d.ms)).y(d => y2(d.avg)));

    svg.append("path")
        .datum(sdt2.data)
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1.5)
        .attr("d", d3.line().x(d => x2(d.ms)).y(d => y2(d.avg)));

		svg.append("g")
			.attr("transform", "translate(0," + gheight / 2 + ")")
			.call(d3.axisBottom(x2))

		svg.append("g")
			.call(d3.axisLeft(y2))

		// const myBrush = d3.brushX()
		// 	.extent([[0, 0], [gwidth, gheight / 2]])
		// 	.on("brush", brushed)
		// 	.on("end", redraw);

		// svg.append("g")
		// 	.attr("class", "brush")
		// 	.call(myBrush)
		// 	.call(myBrush.move, [
		// 		this.state.lastW,
		// 		this.state.lastE
    //   ])
      
    // svg.append("g")
    //   .selectAll("rect")
    //   .data(sdt.data)
    //   .enter()
    //   .append("rect")
    //   .attr("x", d => x2(d.ms))
    //   .attr("y", 0)
    //   .attr("width", 1)
    //   .attr("height", gheight / 2)
    //   .attr("fill", "transparent")
    //   .attr("class", "bar")
    //   .on("mouseover", (d) => { console.log(d.time, d.ms, d.avg); setState({ currentTime: d.time })})
    //   .on("mousemove", () => {})
    //   .on("mouseout", () => {})

		// function brushed() {
    //   			// console.log(myBrush.extent())
    //   // const { lastW, lastE, focusStartTime, focusLastTime } = _self.state;
		// 	// var sel = d3.brushSelection(d3.select(".brush").node())
		// 	// if (sel != null) {
    //   //   if (lastW !== sel[0] || lastE !== sel[1] || focusStartTime.getDate() !== x2.invert(sel[0]).getDate() || focusLastTime.getDate() !== x2.invert(sel[1]).getDate()) {
    //   //     console.log(lastW, sel[0], lastE, sel[1], focusStartTime, x2.invert(sel[0]), focusLastTime, x2.invert(sel[1]));
    //   //     _self.setState({
    //   //       lastW: sel[0],
    //   //       lastE: sel[1],
    //   //       focusStartTime: x2.invert(sel[0]),
    //   //       focusLastTime: x2.invert(sel[1]),
    //   //     })
    //   //   }
		// 	// }
    // }
    
		// function redraw() {
    //   const { lastW, lastE, focusStartTime, focusLastTime } = _self.state;
		// 	var sel = d3.brushSelection(d3.select(".brush").node())
		// 	if (sel != null) {
    //     if (lastW !== sel[0] || lastE !== sel[1] || focusStartTime.getDate() !== x2.invert(sel[0]).getDate() || focusLastTime.getDate() !== x2.invert(sel[1]).getDate()) {
    //       console.log(lastW, sel[0], lastE, sel[1], focusStartTime, x2.invert(sel[0]), focusLastTime, x2.invert(sel[1]));
    //       _self.setState({
    //         lastW: sel[0],
    //         lastE: sel[1],
    //         focusStartTime: x2.invert(sel[0]),
    //         focusLastTime: x2.invert(sel[1]),
    //       })
    //     }
		// 	}
		// }

	}


  modeSwitch = (id) => {
    const elms = Array.prototype.slice.call(document.getElementById('modeSwitch').children);
    console.log(elms);
    elms.forEach(elm => {
      elm.classList.remove("font-weight-bold");
    });
    document.getElementById(id).classList.add("font-weight-bold");
  };

  updatePCs(state) {
    const setState = this.setState.bind(this);
    state.dmap = this.state.dmap;
    const keys = Object.keys(state.dmap);
    state.geList.forEach((ge, i) => {
      if (!keys.includes(ge.deviceId)) {
        state.dmap[ge.deviceId] = {
          i,
          mac: ge.mac,
          location: ge.location,
          cx: ge.cx,
          cy: ge.cy,
          degx: ge.degx,
          degy: ge.degy,
          height: ge.height,
          lon: ge.lon,
          lat: ge.lat,
          data: [],
        };
      } else {
        state.dmap[ge.deviceId].mac = ge.mac;
        state.dmap[ge.deviceId].location = ge.location;
        state.dmap[ge.deviceId].cx = ge.cx;
        state.dmap[ge.deviceId].cy = ge.cy;
        state.dmap[ge.deviceId].degx = ge.degx;
        state.dmap[ge.deviceId].degy = ge.degy;
        state.dmap[ge.deviceId].height = ge.height;
        state.dmap[ge.deviceId].lon = ge.lon;
        state.dmap[ge.deviceId].lat = ge.lat;
        if (state.dmap[ge.deviceId].i !== i) {
          state.dmap[ge.deviceId].i = i;
          state.dmap[ge.deviceId].data.forEach(d => d.i = i);
        }
      }
    });
    setState(state);
  }

  clearDmap() {
    const { geList } = this.state;
    const dmap = {};
    geList.forEach((ge, i) => {
      dmap[ge.deviceId] = {
        i,
        mac: ge.mac,
        location: ge.location,
        cx: ge.cx,
        cy: ge.cy,
        degx: ge.degx,
        degy: ge.degy,
        height: ge.height,
        lon: ge.lon,
        lat: ge.lat,
        data: [],
      };
    });
    this.setState({
      dmap,
      startTime: new Date(),
      lastTime: this.zero,
      focusStartTime: new Date(),
      focusLastTime: this.zero,
      currentTime: this.zero,
      minTemp: 35,
      maxTemp: 5,
      avgMinTemp: 35,
      avgMaxTemp: 5,
    });
  }

  // postMsg() {
  //   const { worker } = this;
  //   const month = document.getElementById("month");
  //   const date = document.getElementById("date");
  //   const mt = month.options[month.selectedIndex].text;
  //   const dt = date.options[date.selectedIndex].text;
  //   worker.postMessage({command:"read", message: mt+"-"+dt});
  // }

  toggleReceive(val = void 0) {
    const setState = this.setState.bind(this);
    setState({ stopReceive: val !== void 0 ? val : !this.state.stopReceive });
  }


  render() {
    const { modeSwitch, margin } = this;
    const { geList, pcMatrix, startTime, lastTime, focusStartTime, focusLastTime, dmap, gwidth, gheight, minTemp, maxTemp, currentTime } = this.state;
    const clearDmap = this.clearDmap.bind(this);
    // const postMsg = this.postMsg.bind(this);
    const toggleReceive = this.toggleReceive.bind(this);

		let focusStartTimeHtml = date2str(focusStartTime, "YYYY/MM/DD hh:mm:ss");
		let focusLastTimeHtml = date2str(focusLastTime, "YYYY/MM/DD hh:mm:ss");
		if (focusStartTime.getTime() !== startTime.getTime()) {
			focusStartTimeHtml = <span className='modified'>{focusStartTimeHtml}</span>;
		}
		if (focusLastTime.getTime() !== lastTime.getTime()) {
			focusLastTimeHtml = <span className='modified'>{focusLastTimeHtml}</span>;
		}

    return (
      <div className="App">
        <section className="App-intro">
          <Router>
            <div>
              <nav className="navbar navbar-expand-sm navbar-light bg-light fixed-top">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <h3 className="navbar-brand">GridEye VIS</h3>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ /* eslint-disable-line jsx-a11y/anchor-is-valid */}
                        Menu
                      </a>
                      <div id="modeSwitch" className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <Link onClick={() => modeSwitch("/")} id="/" to="/" className="dropdown-item">時系列分析</Link>
                        <Link onClick={() => modeSwitch("/sensorconfig")} id="/sensorconfig" to="/sensorconfig" className="dropdown-item">センサ設定</Link>
                      </div>
                    </li>
                  </ul>
                  <ul className="navbar-nav mr-0">
                    {/* <li className="nav-item mr-2">
                      <button className="btn btn-info my-2 my-sm-0" onClick={() => postMsg()}>dt</button>
                    </li> */}
                    <li className="nav-item mr-2">
                      <div className="date-indicator-start mr-2" id="date-indicator-start">
                        {focusStartTimeHtml}
                      </div>
                      <div className="date-indicator-last" id="date-indicator-last">
                        {focusLastTimeHtml}
                      </div>
                    </li>
                    <li className="nav-item">
                      <button className="btn btn-success my-2 my-sm-0" onClick={() => toggleReceive(false)}>▶</button>
                    </li>
                    <li className="nav-item">
                      <button className="btn btn-danger my-2 my-sm-0" onClick={() => toggleReceive(true)}>■</button>
                    </li>
                    <li className="nav-item">
                      <button className="btn btn-danger my-2 my-sm-0" onClick={() => clearDmap()}>0</button>
                    </li>
                  </ul>
                </div>
              </nav>

              <div className="w-100" style={{ height: '80px' }}></div>

              <div name={"placeBase-new"}>
                <div id={"datavisBase-new"} height="25px">
                </div>
              </div>

              <Switch>
                <Route exact path="/">
                  <Home
                    geList={geList}
                    pcMatrix={pcMatrix}
                    dmap={dmap}
                    currentTime={currentTime}
                    gwidth={800}
                    gheight={800}
                    margin={margin}
                    minTemp={minTemp}
                    maxTemp={maxTemp}
                  />
                </Route>
                <Route path="/sensorconfig">
                  <SensorConfig
                    updatePCs={this.updatePCs.bind(this)}
                    geList={geList}
                    pcMatrix={pcMatrix}
                  />
                </Route>
                <Route>
                  Not found.
                </Route>
              </Switch>
            </div>
          </Router>
        </section>
      </div>
    );
  };

}

export default App;
