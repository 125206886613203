const crypto = require('crypto');

export const sensorsHash = sensorsNoArray => {
    const str = sensorsNoArray.join(",");
    const md5 = crypto.createHash('md5');
    return md5.update(str, 'binary').digest('hex');
};

export const date2str = (date, format) => {
    if (!format) format = 'YYYY/MM/DD hh:mm:ss';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const secounds = date.getSeconds();

    const replaceStrArray = {
        'YYYY': year,
        'Y': year,
        'MM': ('0' + (month)).slice(-2),
        'M': month,
        'DD': ('0' + (day)).slice(-2),
        'D': day,
        'hh': ('0' + hours).slice(-2),
        'h': hours,
        'mm': ('0' + minutes).slice(-2),
        'm': minutes,
        'ss': ('0' + secounds).slice(-2),
        's': secounds,
    };

    const replaceStr = '(' + Object.keys(replaceStrArray).join('|') + ')';

    return format.replace(new RegExp(replaceStr, 'g'), str => replaceStrArray[str]);
};
